.card {
    height: 400px;
}
.portfolioImage, .card-image {
    height: 300px;
    
}
.card-content { /*gets overwritten by materialize*/
    text-align: center;
    padding: 15px;
}
span.card-title { /*gets overwritten by materialize*/
    margin: 0px;
}
.btn {
    margin: 5px 5px;
}


@media screen and (max-width: 992px) {
    .card {
        height: 300px;
    }
    .portfolioImage, .card-image {
        height: 200px;
    }
}
@media screen and (max-width: 600px) {
    .card {
        height: 250px;
    }
    .portfolioImage, .card-image {
        height: 150px;
    }
}

