#headshot > img {
  max-height: 450px;
  max-width: 100%;
  aspect-ratio: 1/1;
  height: auto;
  overflow: hidden;

  border-radius: 50%;
  border: 5px solid red;
  /* padding: 5px; */

  object-fit: cover;
}