#skills {   
    background-color: white;
    margin-bottom: 100px;
    padding: 10px 20px;  
}

.skill-img {
    object-fit: contain;
    height: 100px;
    width: 100px;
    margin: 10px 10px;
}

/* img {
    object-fit: contain;
    height: 100px;
    width: 100px;
    margin: 10px 10px;
} */

