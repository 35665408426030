body{
    font-family: 'Nunito', sans-serif;
    /* background-image: url("./assets/otherPics/circuitboard.jpg");
    background-position: center;
    background-repeat: no-repeat;

    background-size: cover; */
}

/* main {
    padding-left: 300px;
}
    
@media only screen and (max-width : 992px) {
    main {
        padding-left: 0;
    }
} */