footer.page-footer {
    background-color: #14b5d0 ;
    width: 100%;    
    padding: 20px auto 20px auto;
}
.footer-main {
    display: flex;
    justify-content: space-around;
    padding: 20px auto;
}

.left {
    flex-grow: 1;
    width: 35%;
    margin: 0 auto;
    padding: 0 15px;
}
.middle {
    text-align: center;
    padding: 0px;
    flex-grow: 2;
}

.right {
    width: 30%;
    padding: 0px 15px;
    text-align: center;
    flex-grow: 1;
}



.contact-left {
    position: relative;
    float: left;
    text-align: center;
    margin: 0 auto;
}
.icon {
    position: relative;
    background-size: 48px;
	width: 48px;
    height: 48px;
    margin-top: 10px;
	margin-right: 10px;
    display: inline-block;
}
.icon-linkedin{
	background-image: url("../../assets/otherPics/linkedin-icon.png");
}
.icon-github{
    background-image: url("../../assets/otherPics/GitHub-Mark-64px.png");
}
.icon-email{
    background-image: url("../../assets/otherPics/gmail.png");
}

#attributeArea {
    height: 100%;
    width: 100%;
}

#attributeArea > p {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;
    font-size: 11px;
}
