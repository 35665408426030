.timeline {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}
.timeline .timeline-item {
  position: relative;
}
.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}
.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -38px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}
