#about {
    margin-bottom: 100px;
    background-color: white;
    padding: 10px 20px;
}
#photoAndResumes {
    padding: 10px;
}
li {
    text-align: center;
}




.logosDiv{
    text-align: center;
}
.psuLogo, .bootCampLogo, .sjLogo{
    display: inline-block;
}
.psuLogo > img, .bootCampLogo > img, .sjLogo > img {
    max-height: 150px; 
    max-width: 100%;   
}

@media screen and (max-width: 992px) {
    #links > .logosDiv {
        display: none;
    }
    .article-container > .logosDiv {
        display: block;
    }
}
@media screen and (min-width: 993px) {
    #links > .logosDiv {
        display: block;
    }
    .article-container > .logosDiv {
        display: none;
    }
}

